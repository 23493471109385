import { setup_tooltip } from "./../common_functions.js";
document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		$(".nav-item:not(.logged-in)").first().hide();
		// setting Help icon to navbar Helpdesk button
		$(".dropdown-toggle").html(
			'<svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z" fill="#000" style=""></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" fill="#000" style=""></path></svg>'
		);
		const path = window.location.pathname;
		const contractPaths = [
			{ path: "/trade-term-contract/list", label: "Trade Term" },
			{ path: "/freight-allowance-addendum-contract/list", label: "Freight Allowance" },
			{ path: "/vendor-data-services-contract/list", label: "Vendor Data Services" },
			{ path: "/media-and-advertising-contract/list", label: "Media and Advertising" },
			{ path: "/autoship-and-save-contract/list", label: "Autoship and Save" },
			{ path: "/promotion-contract/list", label: "Promotion" },
			{ path: "/contract-repository/list", label: "Contract Repository" },
		];
		if (path != "/contract-repository/list") {
			const contractDropdown = $("<select>", { id: "contract-dropdown" });
			contractPaths.forEach((contract) => {
				const option = $("<option>").attr("value", contract.path).text(contract.label);

				// Set the option as selected if it matches the current path
				if (contract.path === path) {
					option.attr("selected", "selected");
				}
				contractDropdown.append(option);
			});

			// Append the dropdown to the page (choose an appropriate container or replace the list container)
			$(".web-list-container").children().eq(0).before(contractDropdown);

			// Listen to dropdown changes and navigate to the selected page
			$("#contract-dropdown").change(function () {
				const selectedPath = $(this).val();
				window.location.href = selectedPath;
			});
		}
		if (contractPaths.some((contract) => contract.path === path)) {
			$(".web-sidebar .sidebar-items ul.list-unstyled:last li.sidebar-item:last").remove();
			setup_tooltip(path);
			const listContainer = $(".web-list-container");
			if (path != "/contract-repository/list") {
				if (listContainer.length > 0) {
					// const tabs = ["Action Needed", "Pending", "Completed", "Expired", "Rejected"];
					// const newItem = $("<div>", { class: "rebate-contract-filters" });
					// createTabs(tabs, newItem, "#workflow_state");
					// listContainer.children().eq(0).after(newItem);
				}
			}
		}
		// For SKU Request Change web list view
		else if (path === "/sku-request-change/list") {
			$('[id="contract-dropdown"]').hide();
			$(".text-center").hide();
			$(".page_content").css({ "max-width": "none" });
			$(".web-list-header").hide();
			const observer1 = new MutationObserver(function (mutationsList, observer) {
				mutationsList.forEach(function (mutation) {
					if (mutation.type === "childList") {
						if ($("a.btn-new-doc").length > 0) {
							$("a.btn-new-doc").removeClass("hidden-xs").hide();
						}
						$(".text-center").each(function () {
							const paragraph = $(this).find("p.small.mb-2");
							if (paragraph.length && paragraph.text().trim() === "No SKU Request Change found") {
								paragraph.text("No record found");
							}
						});
					}
				});
			});
			const targetNode = document.body; // You can specify a more specific container if needed
			const config = { childList: true, subtree: true }; // Observe additions to any child in the DOM
			observer1.observe(targetNode, config);
			setTimeout(() => {
				$('select[data-fieldname="vendor_status"]').prepend(
					$("<option>", {
						value: "",
						text: "Status",
						disabled: true,
						selected: true,
						hidden: true,
					})
				);
				$('input[data-fieldname="submission_id"]')
					.val(JSON.parse(localStorage.getItem("cost_update_requests")))
					.trigger("change");
			}, 500);
			const listContainer = $(".web-list-container");
			createActions(listContainer);
			setupCheckboxListener();
			// Use MutationObserver to monitor DOM changes and reapply event listeners
			const observer = new MutationObserver(function (mutationsList) {
				for (let mutation of mutationsList) {
					if (mutation.type === "childList") {
						// Reapply event listeners when DOM changes
						setupCheckboxListener();
					}
				}
			});

			// Observe changes to the list container
			observer.observe(listContainer[0], { childList: true, subtree: true });
		}
		// For Cost Update Request web list view
		else if (path === "/cost-update-request/list") {
			$(".page_content").css({ "max-width": "none" });
		}

		// For Contract Repository web list view
		if (path === "/contract-repository/list") {
			$(".page_content").css({ "max-width": "none" });

			const observer = new MutationObserver(() => {
				$("#contract-dropdown").hide();
				let $doctypeInput = $('.form-group[data-fieldname="reference_doctype"] input');
				let $docnameInput = $('.form-group[data-fieldname="reference_docname"] input');

				if ($doctypeInput.length) {
					$doctypeInput.attr("placeholder", "Contract Type");
				}

				if ($docnameInput.length) {
					$docnameInput.attr("placeholder", "Contract ID");
				}

				if ($doctypeInput.length && $docnameInput.length) {
					observer.disconnect(); // Stop observing once fields are found
				}
			});

			// Observe the body for changes
			observer.observe(document.body, { childList: true, subtree: true });
		}

		setTimeout(() => {
			if (
				path === "/trade-term-contract/list" ||
				path === "/freight-allowance-addendum-contract/list" ||
				path === "/vendor-data-services-contract/list" ||
				path === "/media-and-advertising-contract/list" ||
				path === "/promotion-contract/list" ||
				path === "/autoship-and-save-contract/list"
			) {
				// Rearrange the filter fields based on the new order
				["supplier_name", "supplier_id", "trade_terms_type", "start_date", "end_date"].forEach(
					(fieldName) => {
						const $filter = $(`.web-list-filters .form-group[data-fieldname='${fieldName}']`);
						$(".web-list-filters").append($filter);
					}
				);
				// handled diffrent field names for adding place holder
				const contractStatusField = {
					"/trade-term-contract/list": "vendor_contract_status",
					"/freight-allowance-addendum-contract/list": "contract_status",
					"/vendor-data-services-contract/list": "contract_status",
					"/media-and-advertising-contract/list": "vendor_contract_status",
					"/autoship-and-save-contract/list": "vendor_contract_status",
				};
				addPlaceHolderToSelectField("trade_terms_type", "Trade Term Type");
				if (path in contractStatusField) {
					addPlaceHolderToSelectField(contractStatusField[path], "Vendor Contract Status");
				}
				$(".rebate-contract-filters").find("button:first").trigger("click");
			}
		}, 500);
	});
});

function createTabs(tabs, container, targetField) {
	const buttons = tabs.map((text) => $("<button>").text(text));
	container.html(buttons);

	container.on("click", "button", function () {
		setup_tooltip(window.location.pathname);
		const tabText = $(this).text().toLowerCase().replace(" ", "_");
		$(this).addClass("selected").siblings().removeClass("selected");
		$(targetField).val(tabText);
		const dropdown = $(`[data-fieldname="${targetField.substring(1)}"] select`);
		dropdown.val(tabText).trigger("change");
	});

	$(document).on("change", `[data-fieldname="${targetField.substring(1)}"] select`, function () {
		const selectedValue = $(this).val();
		const tab = container.find("button").filter(function () {
			return $(this).text().toLowerCase().replace(" ", "_") === selectedValue;
		});
		tab.addClass("selected").siblings().removeClass("selected");
	});
}

function addPlaceHolderToSelectField(fieldName, placeholderText) {
	let $selectField = $(`select[data-fieldname="${fieldName}"]`);
	if ($selectField.length) {
		$selectField.prepend(`<option value="" disabled selected>${placeholderText}</option>`);
	}
}

function createActions(listContainer) {
	const helpdeskIcon = $(".navbar-nav .nav-item.dropdown").first().detach();
	let html = `
<div id="actions-container" style="height:45px;">
<div style="height:100%; display:inline-block; background-color: transparent;">
<a href="/manage_costs"><button class="tab-button" id="request-cost-update" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Request Cost Update</button></a>
</div>
<div style="height:100%; display:inline-block; background-color: transparent;" id="default-tab">
<a href="/manage_costs#history-tab"><button class="tab-button" id="cost-update-history" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Cost Update History</button></a>
</div>
<div id="helpdesk" style="display:inline-block;"></div>
<div class="dropdown-cu" style="display:none; float: right; margin-top: 75px;">
<button type="actions" class="action-btn btn btn-primary btn-sm ml-1">Actions</button>
<div class="dropdown-cu-content" style="
			background-color: #ffffff;
			min-width: 132px;
		">
<button id="download-btn" style = "background-color: white !important; color: #495057; font-size: inharit;padding: 5px; border-bottom: 2px solid #DCDCDC;">Download</button>
<button id="cancel-btn" style = "background-color: white !important; color: #495057; font-size: inharit;padding: 5px; ">Cancel Request</button>
</div>
</div>
</div>
`;

	listContainer.children().eq(0).after(html);
	$("#helpdesk").append(helpdeskIcon);
	$(helpdeskIcon).css({
		display: "inline-block",
	});
	// jQuery to handle button activation and dropdown functionality
	$("#actions-container button.tab-button").on("click", function () {
		// Remove active state (color and border) from all buttons and divs
		$("#actions-container button.tab-button").css({
			color: "grey",
			"background-color": "transparent",
		});
		$("#actions-container div").css({
			"border-bottom": "none",
		});

		// Add active state (black color for button and blue underline for div) to the clicked button and its parent div
		$(this).css({
			color: "black",
			"background-color": "transparent", // optional, can add background if needed
		});

		$(this).closest("div").css({
			"border-bottom": "3px solid #0d6efd", // Apply blue underline to div
		});
	});

	// Set the default tab style (black color for the button and blue underline for its parent div)
	$("#cost-update-history").css({
		color: "black",
	});
	$("#default-tab").css({
		"border-bottom": "3px solid #0d6efd", // Default blue underline
	});
	$("#download-btn, #cancel-btn").hover(
		function () {
			$(this).css({
				"background-color": "#f8f9fa", // Light gray background for hover
				color: "#000", // Black text
				cursor: "pointer", // Pointer cursor
			});
		},
		function () {
			$(this).css({
				"background-color": "white", // Reset background color
				color: "#495057", // Reset text color
			});
		}
	);
}

function setupCheckboxListener() {
	setTimeout(function () {
		// Select all row checkboxes
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');

		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", handleSelectionChange);
		});
		$("#download-btn").off("click").on("click", downloadSkus);
		$("#cancel-btn").off("click").on("click", cancelSkus);
	}, 2000);
}
function cancelSkus() {
	frappe.call({
		method: "erpnext_custom_app.www.manage_costs.index.cancel_selected_sku_records",
		args: {
			skus: handleSelectionChange(),
		},
		callback: function (response) {
			const { message, skus = [] } = response.message || {};

			// Combine the alert messages
			let alertMessage = `${message}`;
			if (skus.length > 0) {
				let skusMessage = skus.map((sku) => sku.id).join(", ");
				alertMessage += `<br><br>Updated SKUs: ${skusMessage}`;
			}

			// Show the combined alert message
			frappe.show_alert({ message: alertMessage, indicator: "green" });

			// Reload the page after the operation
			window.location.reload();
		},
	});
}

function handleSelectionChange() {
	// Get all selected rows
	let selectedRows = [];
	const selectedCheckboxes = document.querySelectorAll(
		'.list-col-checkbox input[type="checkbox"]:checked'
	);

	selectedCheckboxes.forEach((checkbox) => {
		// Get the parent row ID (from the <tr id="...">)
		let row = checkbox.closest("tr");
		let recordId = row.getAttribute("id");
		selectedRows.push(recordId);
	});

	// Run your custom function when records are selected
	processSelectedRecords(selectedRows);
	return selectedRows;
}

function processSelectedRecords(selectedRecords) {
	if (selectedRecords.length > 0) {
		$(".dropdown-cu").css({ display: "block" });
	} else {
		$(".dropdown-cu").css({ display: "none" });
	}
}

function downloadSkus() {
	window.open(
		"/api/method/erpnext_custom_app.www.manage_costs.index.download_excel?skus=" +
			JSON.stringify(handleSelectionChange())
	);
}
